.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.folder {
    margin: 20px;
    text-align: center;
}

.folder img {
    width: 300px;
    height: 200px;
    cursor: pointer;
    border-radius: 3%;
    transition: transform 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.caption {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
}