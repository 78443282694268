/* Footer.css */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #1c1c1c;
    color: #fff;
    width: 100%; /* S'assurer que le footer occupe toute la largeur de l'écran */
    position: fixed;
    bottom: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

}

.footer p {
    margin: 0;
    font-size: 16px;
}

@media (max-width: 768px) { /* Styles pour les tablettes et téléphones */
    .footer {
        padding: 15px; /* Réduire l'espace en version mobile */
        font-size: 14px; /* Réduire légèrement la taille du texte */
    }
}

@media (max-width: 480px) { /* Styles pour les petits téléphones */
    .footer {
        padding: 10px; /* Encore moins d'espace pour les petits écrans */
        font-size: 12px; /* Plus petite taille du texte */
    }
}
